import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import Logo from "images/svg/PGT-PV-Solar-Logo.svg"

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Utility Scale', href: '/utility-scale' },
  { name: 'Rooftop', href: '/rooftop' },
  { name: 'Solar Canopies', href: '/canopies' },
  { name: 'EV Charging', href: '/ev-charging' },
  { name: 'About Us', href: '/about-us' },
  { name: 'Insights', href: '/articles' },
  { name: 'Contact', href: '/contact' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const NavBar = ({ siteTitle }) => {
  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="container px-2 lg:px-8">
            <div className="relative flex items-center justify-between h-16">

              <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="flex-1 flex items-center justify-left lg:items-stretch lg:justify-between">

                <div className="flex-shrink-0 flex items-center">
                  <Link to="/"><Logo className="w-56 xl:w-64" /></Link>
                </div>

                <div className="hidden lg:block lg:ml-6">
                  <div className="nav-links flex h-full items-center space-x-1 xl:space-x-2 justify-center">
                    {navigation.map((item, key) => (
                      <Link
                        key={key}
                        to={item.href}
                        className="text-dark hover:bg-primary-400 hover:text-white px-2 py-2 rounded-md text-sm font-medium"
                        activeClassName="active"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="container px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-navy text-white' : 'text-navy hover:bg-primary-500 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}


NavBar.propTypes = {
  siteTitle: PropTypes.string,
}

NavBar.defaultProps = {
  siteTitle: ``,
}

export default NavBar