import * as React from 'react'
import { Link } from "gatsby"

import Logo from "images/svg/PacificGreenLogo.svg";
import { ArrowRightIcon } from '@heroicons/react/solid'

const Footer = () => {

    return (

        <footer className="footer py-16 border-t">

            <div className="container lg:flex lg:justify-between space-y-4 lg:space-y-0">

                <div className="w-full">
                    <a href="https://www.pacificgreen-group.com" target="_blank" rel="noreferrer" className="mb-2 block">
                        <Logo className="w-64" />
                    </a>
                    <div className="text-xs mb-4 text-gray-500">Part of the Pacific Green Group of companies, © {new Date().getFullYear()}</div>
                    <div className="invisible lg:visible">
                        <div className="flex">
                            <a href="https://www.pacificgreen-group.com" className="btn-secondary btn-small btn-icon">
                                Visit
                                <ArrowRightIcon className="ml-4 h-5 w-5 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="w-full">
                    <div className="text-sm flex lg:flex-col space-x-4 lg:space-x-0 lg:space-y-2 items-center lg:items-start">
                        <Link to="/about-us">About Us</Link>
                        <a href="https://www.pacificgreen-group.com/investors">Investors</a>
                        <Link to="/contact">Contact Us</Link>
                    </div>
                </div>

                <div className="w-full lg:text-right">
                    <p className="text-sm font-semibold">4 Albemarle St, Mayfair. London. UK. W1S 4GA</p>
                    <p className="text-sm">Tel: +44 (0)20 3988 3399 - Email: solar-pv@pacificgreen.tv</p>
                </div>

            </div>
        </footer>

    )
}

export default Footer